<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <Breadcrumb :datas='breadcrumb_data' />
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container-fluid">
        
        <ListTable providerType="deposit" key="deposit" />
        <div class="my-5"></div>
        <ListTable providerType="withdraw" key="withdraw" />

      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import { mapState } from 'vuex';
import ListTable from './ListTable.vue';

export default {
  name: 'payment_methods',
  data() {
    return {
    }
  },
  components: {
    Breadcrumb,
    ListTable,
  },
  computed: {
    breadcrumb_data() {
      return {
        title:  this.$t('general_management.title'),
        subtitle: [
          this.$t('general_management.payment_methods_title')
        ]
      }
    },
    ...mapState({
      permissions: (state) => state.customer.permissions,
    }),
  },
  methods: {
    checkPermission(permission) {
      return this.permissions?.some(
        (item) => String(item.name) === String(permission)
      );
    },
  },
  created() {
    if (this.permissions?.length > 0 && !this.checkPermission("crm.providers.payments.index")) {
      this.$router.push("/no-permission");
    }
  }
}
</script>
